import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/Login'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const Settings = React.lazy(() => import('../pages/Settings'));
const Community = React.lazy(() => import('../pages/Community'));
const TwoFactorAuth = React.lazy(() => import('../pages/TwoFactorAuth'));

// attribute -  path : string - exact: boolean -
// route : component (which router component will render the att component)
// at end, put the route in array "routes"

// root
function LoginRedirect() {
  return <Redirect to="/home/trackerboard/queue" />;
}

const root = {
  path: '/',
  component: LoginRedirect,
  exact: true,
  route: Route,
};

// home
const home = {
  path: '/home',
  component: Home,
  exact: false,
  route: PrivateRoute,
};

// login
const login = {
  path: '/login',
  component: Login,
  exact: false,
  route: PublicRoute,
};

// reset password
const resetPassword = {
  path: '/reset-password/:token',
  component: ResetPassword,
  exact: false,
  route: Route,
};

// forgot password
const forgotPassword = {
  path: '/forgot-password',
  component: ForgotPassword,
  exact: false,
  route: PublicRoute,
};

// community
const community = {
  path: '/Community',
  component: Community,
  exact: false,
  route: PrivateRoute,
};

// settings
const settings = {
  path: '/settings',
  component: Settings,
  exact: false,
  route: PrivateRoute,
};

// two-factor-auth
const twoFactorAuth = {
  path: '/validateSignIn/:verificationId',
  component: TwoFactorAuth,
  exact: false,
  route: PublicRoute,
};

const routes = [
  root,
  login,
  resetPassword,
  forgotPassword,
  home,
  community,
  settings,
  twoFactorAuth,
];

export default routes;
