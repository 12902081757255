import {
  UserApiFactory,
  AuthApiFactory,
  AccountApiFactory,
  FavoriteAccountApiFactory,
  MeApiFactory,
  MembershipApiFactory,
  RecentAccountApiFactory,
} from './user-api';

import {
  QueueApiFactory,
  QueueEntryHistoryApiFactory,
  CommunityPostApiFactory,
  CommunityAttachmentApiFactory,
  CommunityUserBanApiFactory,
  CommunityPostReportApiFactory,
  CommunityPromptApiFactory,
  CategoryApiFactory
} from './queue-api';

import { authlessClient, baseURL, client } from './client';
import { ChatApiFactory, ChatBotApiFactory } from './chat-api';
import {
  CoachNoteApiFactory,
  FeedApiFactory,
  FeedAttachmentApiFactory,
  HealthServiceApiFactory
} from './health-api';
import { MeetingApiFactory } from './telephony-api';

// user-api
export const accountApiClient = AccountApiFactory(undefined, baseURL, client);
export const meApiClient = MeApiFactory(undefined, baseURL, client);
export const userApiClient = UserApiFactory(undefined, baseURL, client);
export const authApiClient = AuthApiFactory(undefined, baseURL, authlessClient);
export const favoriteAccountApiClient = FavoriteAccountApiFactory(undefined, baseURL, client);
export const membershipApiClient = MembershipApiFactory(undefined, baseURL, client);
export const recentAccountApiClient = RecentAccountApiFactory(undefined, baseURL, client);

// chat-api
export const chatApiClient = ChatApiFactory(undefined, baseURL, client);
export const chatbotApiClient = ChatBotApiFactory(undefined, baseURL, client)

// queue-api
export const queueApiClient = QueueApiFactory(undefined, baseURL, client);
export const queueEntryHistoryApiClient = QueueEntryHistoryApiFactory(undefined, baseURL, client);
export const communityPostApiClient = CommunityPostApiFactory(undefined, baseURL, client);
export const communityAttachmentApiClient = CommunityAttachmentApiFactory(undefined, baseURL, client);
export const communityUserBanApiClient = CommunityUserBanApiFactory(undefined, baseURL, client);
export const communityPostReportApiClient = CommunityPostReportApiFactory(undefined, baseURL, client);
export const communityPromptApiClient = CommunityPromptApiFactory(undefined, baseURL, client);
export const categoryApiClient = CategoryApiFactory(undefined, baseURL, client);

// health-api
export const healthApiClient = CoachNoteApiFactory(undefined, baseURL, client);
export const feedApiClient = FeedApiFactory(undefined, baseURL, client);
export const feedAttachmentApiClient = FeedAttachmentApiFactory(undefined, baseURL, client);
export const healthServiceApiClient = HealthServiceApiFactory(undefined, baseURL, client);

// telephony-api
export const telephonyApiClient = MeetingApiFactory(undefined, baseURL, client);
