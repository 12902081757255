import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { ErrorFactory } from './ErrorFactory';
import { ZoomError } from '../../contexts/zoom/types';

export default () => {
  const errorFactory = new ErrorFactory();

  const handleError = (error?: unknown) => {
    Sentry.captureException(error);
  };

  const showErrorMessage = (error?: unknown, errorMessage?: string) => {
    if (!error && !errorMessage) {
      return;
    }

    handleError(error);

    if (errorMessage) {
      toast.error(errorMessage);
      return;
    }

    let message = errorMessage;

    if (typeof error === 'string') {
      message = error;
    }

    if (error instanceof Error) {
      message = error.message;
    }

    if ((error as AxiosError).isAxiosError) {
      const {
        statusCode,
        message: apiErrorMessage,
      } = (error as AxiosError).response?.data || {};

      if (apiErrorMessage) {
        message = apiErrorMessage;
      }
      if (statusCode) {
        message = errorFactory.buildErrorMessage(statusCode);
      }
    }

    if ((error as ZoomError).type) {
      const {
        reason,
      } = (error as ZoomError);

      if (reason) {
        message = reason;
      }
    }

    toast.error(message);
  };

  return { showErrorMessage };
};
