import _ from 'lodash';
import { DateTime } from 'luxon';
import { timeZoneAbbreviations } from '../common/timezones';
import { FeedHealthDataQuantityDto } from '../services/api/health-api';

interface IMappedProps {
  value: any;
  label: any;
  mainCharacter?: string;
}

export const utcDateTime = DateTime.fromISO(
  DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
)
  .toUTC()
  .toString();

export const currentDate = DateTime.now().toFormat('yyyy-MM-dd');

export const afterDate = DateTime.fromISO(
  DateTime.now().plus({ days: -1 }).toFormat("yyyy-MM-dd'T20:00:00'"),
  {
    zone: 'America/Los_Angeles',
  },
)
  .toUTC()
  .toString();

export const beforeDate = DateTime.fromISO(
  DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd'T04:00:00'"),
  {
    zone: 'America/Los_Angeles',
  },
)
  .toUTC()
  .toString();

export const timeZoneValues: { [key: string]: string } = {
  'America/New_York': 'eastern',
  'America/Chicago': 'central',
  'America/Denver': 'mountain',
  'America/Los_Angeles': 'pacific',
};

export const getCurrentTimezone = () => {
  const dataTimeNow = DateTime.now();
  const currentTimezone = timeZoneValues[dataTimeNow.zone.name] || timeZoneValues['America/Los_Angeles'];
  return currentTimezone;
};

export function splitName(fullName: string): [string, string] {
  const firstName = fullName.substring(0, fullName.indexOf(' '));
  const lastName = fullName.substring(fullName.indexOf(' ') + 1);
  return [firstName, lastName];
}

export function calculateAge(birhDate: string) {
  if (birhDate === null) return 0;

  const format = DateTime.fromISO(birhDate);
  const getYears = format.diffNow('years').years;
  const age = Math.trunc(getYears);

  return Math.abs(age);
}

export function formatCountdownPeriod(deletionScheduledFor: string | null) {
  const message = 'This group will be closed';
  const currentUtcDate = DateTime.utc();
  const deletionDate = DateTime.fromISO(deletionScheduledFor!, { zone: 'utc' });
  const {
    days, hours, minutes, seconds,
  } = deletionDate.diff(currentUtcDate, [
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  if (days >= 1) return `${message} in ${days} day${days > 1 ? 's' : ''}`;

  if (hours >= 1) return `${message} in ${hours} hour${hours > 1 ? 's' : ''}`;

  if (minutes >= 1) return `${message} in ${minutes} minute${minutes > 1 ? 's' : ''}`;

  if (Math.trunc(seconds) >= 1) return `${message} in few seconds`;

  return message;
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formatted = parseFloat((bytes / k ** i).toFixed(dm));

  return `${formatted} ${sizes[i]}`;
}

export function getFormattedDiff(value?: string) {
  const time = DateTime.fromISO(value ?? '');

  const formattedTime = DateTime
    .utc()
    .diff(time, ['days', 'hours', 'minutes'])
    .toFormat("dd'd, ' hh'h, ' mm'min'")
    .replace('00d, ', '')
    .replace('00h, ', '')
    .replace('00min', '')
    .trim();

  if (!time.isValid || _.isEmpty(formattedTime)) return 'Just now';
  return `${formattedTime} ago`;
}

export const mapResponseToValuesAndLabels = (mapObject: IMappedProps) => mapObject;

export const getTime = (dateTime: string) => {
  const timeValue = DateTime.fromISO(dateTime).toFormat('HH:mm');
  return timeValue;
};

export const sendUtcDateTime = (
  time: string,
  date: string,
  timezone: string,
) => {
  const [hour, minutes] = time.split(':');
  const eventDate = DateTime.fromISO(date).plus({
    hours: Number(hour),
    minutes: Number(minutes),
  });
  const formattedUtcDateTime = DateTime.fromISO(
    eventDate.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
    {
      zone: timezone,
    },
  );
  return formattedUtcDateTime.toUTC().toString();
};

export const formatTimezoneDateTime = (date: string, timezone: string) => {
  const localDate = DateTime.fromISO(date, {
    zone: timezone,
  }).toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return localDate;
};

export const formatTimezoneTime = (date: string, timezoneValue: string) => {
  const formatedTimezoneDate = DateTime.fromISO(date, {
    zone: timezoneValue,
  }).toFormat('HH:mm');
  return formatedTimezoneDate;
};

export const formatTimezoneDate = (date: string, timezoneValue: string) => {
  const formatedTimezoneDate = DateTime.fromISO(date, {
    zone: timezoneValue,
  }).toFormat('yyyy-MM-dd');
  return formatedTimezoneDate;
};

export const formatEventDate = (date: string, timezoneValue: string) => {
  const formatedTimezoneDate = DateTime.fromISO(date, {
    zone: timezoneValue,
  }).toFormat('MM-dd-yyyy');
  return formatedTimezoneDate;
};

export const setLocalZone = (utcDate: string) => {
  const localDate = DateTime.fromISO(utcDate).toLocal().toString();
  return localDate;
};

export const showFullNameForOwnerAndMembership = (data: any) => {
  const { owner: mainCharacterUser } = data;
  const mainCharacterName = `${mainCharacterUser.firstName} ${mainCharacterUser.lastName}`;
  const accountNames = `${mainCharacterName}`;
  return accountNames;
};

export const showScheduledDate = (startDate: DateTime, endDate: DateTime) => `${startDate.toFormat("ccc',' LLL d H:mm")}-${endDate.toFormat('H:mma')}`;

export function calculateDiffNow(value: string) {
  const diffNow = DateTime.fromISO(value).toRelative({
    unit: 'days',
    locale: 'en',
  });

  if (diffNow === 'in 0 days') return 'Today -';
  if (diffNow === 'in 1 day') return 'Tomorrow -';

  return '';
}

export function formattedScheduledTime(value: string) {
  return DateTime.fromISO(value).toFormat('h:mm a').toLocaleString();
}

export function formatDateCheckIn(value: string) {
  return DateTime.fromISO(value).toFormat('h.mm a').toLocaleString();
}

export function formatDateNote(value: string) {
  return DateTime.fromISO(value).toFormat('MM/dd/yyyy - HH:mm').toLocaleString();
}

export const formattedAppointmentTitle = (
  appointmentType: string,
  mainCharacter: string | undefined,
  persistMainCharacter?: string,
) => {
  const types: { [key: string]: string } = {
    check_in: 'Check-In',
    physician: 'Physician',
    coach: 'Ad-Hoc',
  };

  if (mainCharacter) return `${types[appointmentType]} - ${mainCharacter}`;

  return `${types[appointmentType]} - ${persistMainCharacter}`;
};

export type UserType = 'admin' | 'coach' | 'physician' | 'customer';

function getNumberSuffix(day: string) {
  const th = 'th';
  const rd = 'rd';
  const nd = 'nd';
  const st = 'st';

  if (day === '11' || day === '12' || day === '13') return th;

  const lastDigit = day.slice(-1);

  switch (lastDigit) {
    case '1': return st;
    case '2': return nd;
    case '3': return rd;
    default: return th;
  }
}

export function getMinValue(data: Array<number>) {
  const minValues = data.map((item: any) => item.minimumQuantity);
  const minValue = _.min(minValues) || 0;
  return minValue;
}

export function getMaxValue(data: Array<number>) {
  const maxValues = data.map((item: any) => item.maximumQuantity);
  const maxValue = _.max(maxValues);
  return maxValue;
}

export function getMaxBithDate() {
  const maxDate = DateTime.now()
    .plus({ days: -1 })
    .toFormat('yyyy-MM-dd');
  return maxDate;
}

export function formatDate(value: string) {
  const weekdayAndMonth = DateTime.fromISO(value).toFormat("cccc',' LLLL").toLocaleString();

  const day = DateTime.fromISO(value).toFormat('dd').toLocaleString();
  const dayNumber = `${day}${getNumberSuffix(day)}`;

  const hours = DateTime.fromISO(value).toFormat('hh:mm a').toLocaleString();

  return `${weekdayAndMonth} ${dayNumber} - ${hours}`;
}

export const getTimezoneAbbreviation = (timezone: string) => (
  timeZoneAbbreviations[timezone] ?? 'Unknown Timezone'
);

export function formatDateAppointmentCard(timestamp: string, timezone: string) {
  const time = DateTime
    .fromISO(timestamp)
    .toFormat("ccc',' LLL dd 'at' hh:mm a")
    .toLocaleString();

  return `${time} (${getTimezoneAbbreviation(timezone)})`;
}

export function formatDateWeightBmiCard(value: string) {
  const month = DateTime.fromISO(value).toFormat('LLLL').toLocaleString();

  const day = DateTime.fromISO(value).toFormat('dd').toLocaleString();
  const dayNumber = `${day}${getNumberSuffix(day)}`;

  return `${month} ${dayNumber}`;
}

export function formatWeekday(value: string) {
  return DateTime.fromISO(value).toFormat('ccc');
}

export function diffNowInDays(value: string) {
  return DateTime.fromISO(value).diffNow(['days']).toObject();
}

export function diffNowInHours(value: string) {
  return DateTime.fromISO(value).diffNow(['hours']).toObject();
}

export function formatDateAverageWeek(fromDate: string, toDate: string) {
  const toDay = DateTime.fromISO(toDate).day;
  const fromDay = DateTime.fromISO(fromDate).day;
  const month = DateTime.fromISO(fromDate).toFormat('LLLL');
  const diffNow = diffNowInDays(toDate);

  if (diffNow.days! >= -7) return 'This week';
  if (diffNow.days! >= -14 && diffNow.days! < -6) return 'Last week';

  return `${month} ${fromDay}-${toDay}`;
}

export function sortCardData(data: FeedHealthDataQuantityDto[]) {
  const sortedData = data.slice(0).sort((
    a: FeedHealthDataQuantityDto,
    b: FeedHealthDataQuantityDto,
  ) => DateTime.fromISO(a.fromDate).toMillis() - DateTime.fromISO(b.fromDate).toMillis());
  return sortedData;
}

export const maxDefaultTextAreaRows = 3;

export const minDefaultTextAreaRows = 1;
